import React from "react"

import SEO from "../components/seo"
import LayoutsComponent from '../layouts/LayoutsComponent';
import VerificacionDomicilio from '../components/pages/VerificacionDomicilio'

const VerificacionDomicilioTemplate = ({pageContext, location}) => (

  <LayoutsComponent
    layout={pageContext.layout}
    pageContext={pageContext}
    location={location}
    companyName={pageContext.company}
    gradient
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <VerificacionDomicilio pageContext={pageContext} /> 
      </>
    )}
  />
)

export default VerificacionDomicilioTemplate